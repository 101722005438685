$bg: #313942;
$ghost: #528cce;
$heading: #444;

.Custom404 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;

  h1 {
    color: $heading;
    font-size: 12.5rem;
    letter-spacing: 0.1em;
    margin: 0.025em 0;
    text-shadow: 0.05em 0.05em 0 rgba(0, 0, 0, 0.25);
    white-space: nowrap;

    @media (max-width: 30rem) {
      font-size: 8.5rem;
    }

    & > span {
      animation: spooky 2s alternate infinite linear;
      color: $ghost;
      display: inline-block;
    }
  }

  h2 {
    color: $heading;
    margin-bottom: 0.4em;
  }

  p {
    color: #444;
    margin-top: 0;
  }

  @keyframes spooky {
    from {
      transform: translatey(0.15em) scaley(0.95);
    }

    to {
      transform: translatey(-0.15em);
    }
  }
}
